/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { Layout, List, Menu, NavMenu } from 'tea-component';
import routes from './routes';
import { Link, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import LoginPage from './routes/login';
import { UserResponse } from './common/interface';
import { ModifyPasswordModal } from './routes/accounts/modals/modify-password-modal';
import ErrorBoundary from './common/components/error-boundary';
import { Icon } from 'tea-component/es';
import { LocalSetting } from './common/local-setting';
import { fetchLogout } from './common/apis/accounts';
import { useGotoClick } from './utils/hooks';

const { Body, Content, Sider } = Layout;
const { Header } = Layout;

export const myWorker = new Worker('./worker', { type: 'module' });

const menus: { title: string; icon: string; hoverIcon: string; href: string }[] = [
  {
    title: '证书管理',
    icon: '/static/icon/menu_certificate.svg',
    hoverIcon: '/static/icon/menu_certificate_hover.svg',
    href: '/certificates',
  },
  {
    title: '区块链管理',
    icon: '/static/icon/menu_chain.svg',
    hoverIcon: '/static/icon/menu_chain_hover.svg',
    href: '/chains',
  },
  {
    title: '平台账号管理',
    icon: '/static/icon/menu_account.svg',
    hoverIcon: '/static/icon/menu_account_hover.svg',
    href: '/accounts',
  },
  {
    title: '日志监控',
    icon: '/static/icon/menu_logs.svg',
    hoverIcon: '/static/icon/menu_logs_hover.svg',
    href: '/logs',
  }
];

const MenuWithRouter = withRouter(({ location }) => {
  const pathname = location?.pathname;
  return (
    <Menu theme="dark" title="轮奂链管理平台" collapsable>
      {menus.map((item) => {
        const selected = Boolean(pathname.match(new RegExp(`^${item.href}`)));
        return (
          <Menu.Item
            key={item.title}
            title={item.title}
            icon={selected ? item.hoverIcon : item.icon}
            selected={selected}
            render={(children) => <Link to={item.href}>{children}</Link>}
          />
        );
      })}
      <div className={'menu-tip'}>
        <Icon type={'info'} className={'tea-mr-1n'} />{' '}
        {`支持创建${CHAIN_MAKER.sdkVersion}版本底链，
订阅${CHAIN_MAKER.subscribeMinSdkVersion}+版本底链`}
      </div>
    </Menu>
  );
});

export const AppContext = React.createContext<{
  user: UserResponse | null;
}>({ user: null });

function App() {
  const [user, setUser] = useState<UserResponse | null>(null);
  const history = useHistory();
  const modifyPasswordRef = useRef<any>(null);
  const handleLogout = useCallback(async () => {
    await fetchLogout();
    LocalSetting.logout();
    window.location.href = '/login';
  }, [user]);

  useEffect(() => {
    if (LocalSetting.getToken()) {
      const user = LocalSetting.getUser();
      setUser(user);
    } else {
      history.push({
        pathname: '/login',
      });
    }
  }, []);

  const handleModifyPasswordClick = useCallback(() => {
    modifyPasswordRef.current.showModal();
  }, [modifyPasswordRef]);

  const goClick = useGotoClick('/certificates');

  if (!user) {
    return (
      <Route
        exact
        path="/login"
        render={() => (
          <LoginPage
            onSuccess={(user) => {
              setUser(user);
              goClick();
            }}
          />
        )}
        key="login"
      />
    );
  }
  return (
    <AppContext.Provider value={{ user }}>
      <Layout>
        <Header>
          <NavMenu
            left={
              <Link to="/">
                {/* <img src="/static/icon/logo.svg" alt="logo" /> */}
              </Link>
            }
            right={
              <NavMenu.Item
                type="dropdown"
                overlay={() => (
                  <List type="option">
                    <List.Item onClick={handleModifyPasswordClick}>修改密码</List.Item>
                    <List.Item onClick={handleLogout}>退出</List.Item>
                  </List>
                )}
              >
                {user.UserName}
              </NavMenu.Item>
            }
          />
        </Header>
        <Body className="main-content">
          <Sider>
            <MenuWithRouter />
          </Sider>
          <Content>
            <ErrorBoundary>
              <Switch>{routes}</Switch>
              <ModifyPasswordModal ref={modifyPasswordRef} />
            </ErrorBoundary>
          </Content>
        </Body>
      </Layout>
    </AppContext.Provider>
  );
}

export default App;
