/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { Base64 } from 'js-base64';
import { getFileName } from './request';

export interface Dictionary<T> {
  [index: string]: T;
}

export const PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];

/**
 * 表格列过滤器工具函数
 */
export const tableFilter = {
  all: 'all',
};

export function saveAs(fileName: string, href: string) {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = href;
  link.click();
}

/**
 *
 * @param headerOrFilename 对象应该是header对象
 * @param blob
 */
export function saveBlobAs(headerOrFilename: string | object, blob: Blob | string) {
  let filename;
  if (typeof headerOrFilename === 'object') {
    filename = getFileName(headerOrFilename);
  } else {
    filename = headerOrFilename;
  }
  const blobUrl = URL.createObjectURL(blob instanceof Blob ? blob : new Blob([blob]));
  const link = document.createElement('a');
  link.download = Base64.isValid(filename) ? Base64.decode(filename) : filename;
  link.href = blobUrl;
  link.click();
}

export function pick<T, K extends keyof T>(obj: T, paths: K[] = []): Pick<T, K> {
  return paths.reduce((res: any, k) => {
    if (k in obj) {
      res[k] = obj[k];
    }
    return res;
  }, {});
}

export function omit<T, K extends keyof T>(obj: T, paths: K[] = []): Exclude<T, K> {
  return paths.reduce(
    (res: any, k) => {
      if (k in res) {
        delete res[k];
      }
      return res;
    },
    { ...obj },
  );
}

export function safeParseArrayStr(str: string) {
  try {
    return JSON.parse(str) || [];
  } catch (e) {
    return [];
  }
}

export async function delay(second: number) {
  return new Promise((resolve) => window.setTimeout(resolve, second * 1000));
}

/**
 * 'foo=unicorn&ilike=pizza'
 */
export function stringifyQueryParams(obj: Dictionary<string | number | boolean>) {
  return Object.keys(obj)
    .reduce((res: string[], k) => {
      res.push(`${k}=${obj[k]}`);
      return res;
    }, [])
    .join('&');
}

export function keyBy<T>(arr: T[], key: keyof T): Dictionary<T> {
  return arr.reduce((res: any, item) => {
    res[item[key]] = item;
    return res;
  }, {});
}
