/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import CertificatesPage from './routes/certificates';
import AccountsPage from './routes/accounts';
import SDKPage from './routes/sdk';
import ChainsPage from './routes/chains';
import Logs from './routes/logs';

export default [
  <Route exact path="/certificates" component={CertificatesPage} key="Certificates"/>,
  <Route path="/chains" component={ChainsPage} key="Chains"/>,
  <Route exact path="/accounts" component={AccountsPage} key="Accounts"/>,
  <Route exact path="/sdk" component={SDKPage} key="SDK"/>,
  <Route exact path="/logs" component={Logs} key="Logs"/>,
];
