/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Card, Col, Form, Layout, MetricsBoard, Row, SearchBox, TabPanel, Tabs, Text } from 'tea-component';
import { BlockTable } from './block-table';
import { DealTable } from './deal-table';
import { ContractTable } from './contract-table';
import { ChainDetailContext } from '../chain-detail';
import { useFetchChainGeneral, useFetchHomePageSearch } from 'src/common/apis/chains/hooks';
import { useQueryParams } from '../../../utils/hooks';

const SEARCH_RESULT_TYPE_MAP = {
  0: 'deal',
  1: 'block',
  2: 'contract',
};

const { Content } = Layout;

export function Browser() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { chainId } = useContext(ChainDetailContext);
  const { chain, run: fetchGeneral } = useFetchChainGeneral();
  const { searchResult, fetch: homePageSearch } = useFetchHomePageSearch();
  const METRICS = [
    {
      title: '最新区块高度',
      value: chain?.BlockHeight,
    },
    {
      title: '节点数',
      value: chain?.NodeNum,
    },
    {
      title: '累计交易数',
      value: chain?.TxNum,
    },
    {
      title: '累计合约数',
      value: chain?.ContractNum,
    },
  ];

  const tabs = [
    {
      id: 'block_info',
      label: '区块信息',
    },
    {
      id: 'deal_info',
      label: '交易信息',
    },
    {
      id: 'constract_info',
      label: '合约信息',
    },
  ];

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchResultState, setSearchResultState] = useState<undefined | 'error'>(undefined);
  const { tabId } = useQueryParams<{ tabId: string; }>();
  useEffect(() => {
    if (searchValue && chainId) {
      homePageSearch({
        KeyWord: searchValue,
        ChainId: chainId,
      });
    }
  }, [searchValue, chainId, homePageSearch]);

  useEffect(() => {
    if (!chainId) return;
    fetchGeneral(chainId);
  }, [chainId]);

  useEffect(() => {
    if (searchResult) {
      const { Type, Id } = searchResult;
      if (Type === 3) {
        setSearchResultState('error');
      } else {
        history.push(`${path}/details?type=${SEARCH_RESULT_TYPE_MAP[Type]}&Id=${Id}`);
      }
    }
  }, [searchResult]);

  return (
    <Layout>
      <Content>
        <Content.Header title="区块链管理/区块链浏览器"/>
        <Content.Body full>
          <Form className="size-full-width">
            <Form.Control
              className="tea-pr-0"
              showStatusIcon={false}
              status={searchResultState}
              message={searchResultState === 'error' && <Text>暂无搜索结果</Text>}
            >
              <SearchBox
                placeholder="输入区块链高度/交易哈希/合约名称 查询，区块高度，如108；交易ID，如tx1008"
                value={searchInput}
                onChange={(value) => setSearchInput(value)}
                onSearch={(value) => setSearchValue(value ?? '')}
                onClear={() => {
                  setSearchValue('');
                  setSearchInput('');
                  setSearchResultState(undefined);
                }}
                size="full"
              />
            </Form.Control>
          </Form>

          <Card className="tea-mt-5n">
            <Row showSplitLine>
              {METRICS.map((metric) => (
                <Col span={6} key={metric.title}>
                  <MetricsBoard title={metric.title} value={metric.value}/>
                </Col>
              ))}
            </Row>
          </Card>
          <Layout>
            <Content.Body className="tea-pa-0 tea-mt-5n" full>
              <Tabs tabs={tabs} ceiling className="tea-mt-2n" activeId={tabId} onActive={tab => {
                history.push(`${path}?tabId=${tab.id}`);
              }}>
                <TabPanel id="block_info">
                  <BlockTable/>
                </TabPanel>
                <TabPanel id="deal_info">
                  <DealTable/>
                </TabPanel>
                <TabPanel id="constract_info">
                  <ContractTable/>
                </TabPanel>
              </Tabs>
            </Content.Body>
          </Layout>
        </Content.Body>
      </Content>
    </Layout>
  );
}
