/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Button, Modal } from 'tea-component';

export const showChainAgreement = () => {
  const { destroy } = Modal.show({
    caption: 'Chainmaker管理台收集声明协议',
    size: 'm',
    onClose: () => destroy(),
    children: (
      <>
        <Modal.Body>
          <div
            className={'chain-agreement-body'}
            dangerouslySetInnerHTML={{
              __html:
                '本数据收集服务（以下简称“本服务”）由北京微芯区块链与边缘计算研究院（以下简称“微芯研究院”）提供。请在使用本服务前认真阅读、充足理解本协议各条款，特别是涉及到您重要权益或权利与义务的条款。请您谨慎阅读并选择接受或不接受本协议。除非您理解并接受本协议所有条款，否则请不要同意进而使用本服务。您不接受本服务，将不会对您使用本产品带来障碍。您一经点击同意，即表示您对本协议所有条款已充足理解并完整接受，协议将即时生效。\n' +
                '协议主体\n' +
                '本协议由您与微芯研究院共同缔结，本协议对您与微芯研究院均具有合同效力。\n' +
                '协议效力\n' +
                '您通过网络页面点击确认方式选择接受本协议。本协议自您确认接受之时起生效。\n' +
                '本服务根据产品的更新情况或可能发生变更，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，您可在本系统中查阅最新版协议条款。本服务修改协议条款后，如果您不接受修改后的条款，请立即停止使用本服务，否则将被视为接受修改后的协议。\n' +
                '定义\n' +
                '区块链：账本以区块的形式记录并按照一定的规则形成链式结构，分布式的存储在对等网络中的节点上。\n' +
                'panic日志：轮奂链在运行过程中可能产生的错误日志的一种类型。\n' +
                '管理台：用于快速单机部署和测试轮奂链的一个区块链管理平台\n' +
                '节点：区块链网络中的每一个节点，就相当于存储所有区块信息的每一台电脑或者服务器终端。所有新区块的产生，以及交易的验证与记账，并将其广播给全联盟同步，都由节点完成。\n' +
                '费用\n' +
                '本服务不会产生任何费用，如您发现有任何程序向您收取费用，请拒绝并向微芯研究院通报此种情形。\n' +
                '用户行为规范\n' +
                '您保证对接入链的产品和/或在链创建、发布的产品享有所有权及全部知识产权或者享有充分有效的合法授权，您有权对您的产品使用本服务。同时，您声明并保证在使用本服务过程中遵守如下要求：\n' +
                '1、不复制、模仿、修改、翻译、改编、出借、出售、转许可、在信息网络上传播或转让链服务和/或相关软件，也不逆向工程、反汇编、反编译、分解拆卸或试图以其他方式发现链服务/软件的源代码；\n' +
                '2、不发送和储存带有病毒的、蠕虫的、木马和其他有害的计算机代码、文件、脚本和程序；\n' +
                '3、不利用链服务散发大量不受欢迎的或者未经请求的电子邮件、电子广告、营销推广或包含反动、色情等有害信息的信息；\n' +
                '4、不利用链提供的资源和服务上传、下载、储存、发布任何违法违规、侵权、淫秽、色情、不道德、欺诈、诽谤（包括商业诽谤）、非法恐吓或非法骚扰等信息或内容，不为他人发布该等信息提供任何便利（包括但不限于设置URL、BANNER链接等）；\n' +
                '5、不建立或利用有关设备、配置运行与链服务无关的程序或进程，或者编写恶意代码导致大量占用链服务器内存、CPU或者网络带宽资源，给链或链其他用户的网络、服务器（包括但不限于本地及外地和国际的网络、服务器等）、产品/应用等带来严重负荷，影响链与国际互联网或链与特定网络、服务器及链内部的通畅联系，或者导致链或其他用户网站所在的服务器宕机、死机或用户基于链的产品/应用不可访问等；\n' +
                '6、不进行任何破坏或试图破坏网络安全的行为（包括但不限于钓鱼、黑客、网络诈骗、网站或空间中含有或涉嫌散播病毒、木马、恶意代码，及通过虚拟服务器对其他网站、服务器进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DOS等）；\n' +
                '7、不进行任何改变或试图改变链系统配置或破坏系统安全的行为；\n' +
                '8、不侵犯链和/或其关联方或任何第三方的合法权益；\n' +
                '9、如本服务涉及第三方软件之许可使用的，您同意遵守相关的许可协议的约束；\n' +
                '10、不从事其他违法、违规、不恰当或违反本协议的行为。\n' +
                '11、您上传、接入链平台的算法、模型、数据来源合法、不侵犯他人合法权益（包括但不限于最终用户的隐私权等）。您已经获得充分必要的授权、同意和许可，不会与您与第三方所签署的协议冲突或构成对第三方权利的侵犯；如您的产品的最终用户撤销上述授权或许可时，您应当采取必要的技术手段，停止针对上述最终用户使用链服务，并登录我们官网进行退出（opt-out）操作。链将自收到opt-out申请后的合理时间内，不再收集您的产品相关数据且无需承担法律明确规定外的责任，届时您将无法正常使用链服务，您已明确知晓前述风险并愿意自行承担相应后果。如适用，您同时还应遵守友盟欧盟数据处理条款中有关上述问题的相关规定。为进一步保证本款的内容，如链要求，您应及时提供相关证明、协议或资质证照等。您理解并确认：链对您提交的证明、协议或资质证照的查阅仅为形式查阅，即使链进行过查阅，不代表链有义务或有能力确认您对数据的合法收集、披露或使用，您应自觉保证并遵守在本款的承诺和保证；\n' +
                '12、您接入链平台的算法、模型、数据的安全性、稳定性、有效性，不包含任何其他软件程序，不存在病毒、蠕虫、木马和其他有害的计算机的代码、文件、脚本和程序，也不存在任何一项中国互联网协会所公布的恶意软件特征，不会对链和/或其关联方的相关系统造成损害，亦不会对链和/或其关联方或其他第三方数据造成损害；\n' +
                '13、不攻击或通过任何不正当手段窃取、获取链平台中任意第三方的数据；\n' +
                '14、未经权利人同意，不将算法、模型、数据披露或授权第三方使用；\n' +
                '15、如司法机关、行政机关或第三方权利人提出您的算法、模型、数据等涉嫌侵权等合理理由，通知链要求删除您接入到链平台中的部分或全部算法、模型、数据的，链有权按照司法机关、行政机关或第三方权利人的要求进行删除，且无需承担违约责任。\n' +
                '16、链平台不具备数据备份功能。用户理解并同意，数据备份是用户的义务和责任，而非链的义务，链不对用户数据备份工作或结果承担责任。\n' +
                '17、用户同意链有权将其在本协议项下接受的、接触的、收集的以及通过您的产品的统计或分析所得的相关数据信息，在本协议存续期间及本协议终止或解除后，保存在链和/或其关联方服务器中；而且无论基于何种理由，您都不得要求链和/或其关联方删除或销毁相关数据信息。\n' +
                '18、用户应仔细阅读链就链服务在网站上或通过其他方式发布的相关操作指引、说明，依照相关操作指引进行操作。\n' +
                '19、如用户违反法律法规或本协议的约定非法接入、上传、发布和/或不当使用相关算法、模型、数据的，或者用户的相关行为可能侵犯个人隐私、商业秘密或其他用户权益的，链有权采取直接终止本协议、暂停或终止链服务、要求用户立即删除或径行删除相关算法、模型、数据等措施，且前述措施可以合并使用。用户应当以自己的名义独立承担全部责任，并对链、其他用户或任何第三方造成的损失进行赔偿。\n' +
                '20、用户同意接收来自链发出的邮件、信息。\n' +
                '数据收集\n' +
                '本服务是由微芯研究院向您提供的一项数据收集技术服务，主要收集的数据为：\n' +
                '1、链所有节点目录下的 bin/panic.log 和 log/system.log 中的 panic 日志 \n' +
                '2、节点部署目录信息 \n' +
                '3、链配置和链状态相关信息\n' +
                '收集方式\n' +
                '收集方式为：管理台主动请求节点服务器上的 log agent 获取上述数据。\n' +
                '使用目的\n' +
                '使用目的：通过收集在使用链过程中产生的报错日志信息，可以帮用户更快速的发现问题，并解决问题。也方便用户将问题反馈给轮奂链核心研发团队，使团队在帮开发者处理问题的同时，也能持续的修正轮奂链的产品漏洞，提升用户体验。\n' +
                '收集范围\n' +
                '除上述数据外，本协议项下微芯研究院不会收集或记录您的其他个人数据。\n' +
                '安全保护\n' +
                '微芯研究院将会使用管理性、技术性以及物理性保护措施来保护上述数据，确保上述数据的安全性。\n' +
                '体验改善\n' +
                '我们对于上述数据中错误日志的产生以及为您带来不好的使用体验感到抱歉，本服务为了改善您的使用体验而在您使用过程中持续进行。\n' +
                '可能的影响\n' +
                '如您认为不需要接受本服务，您可以点击取消拒绝本服务，取消本服务并不影响您对本产品的任何使用功能，但可能会失去让我们为您持续改进使用体验的机会。\n',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type={'primary'} onClick={() => destroy()}>
            确认
          </Button>
        </Modal.Footer>
      </>
    ),
  });
};
