/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Form, H3, Input, Justify, Layout, List, Select } from 'tea-component';
import { Controller, useForm, useWatch } from 'react-hook-form';
import formUtils, { Validator } from '../../utils/form-utils';
import { useGotoClick, useQueryParams } from '../../utils/hooks';
import {
  useChainSubscribe,
  useFetchCertOrgList,
  useFetchCertUserList,
  useFetchChainSubscribeConfig,
} from '../../common/apis/chains/hooks';
import { ChainSubscribeRequest } from '../../common/apis/chains/interface';
import GlossaryGuide from '../../common/components/glossary-guide';
import { Col, Row, Switch } from 'tea-component/es';
import { omit } from '../../utils/common';

const { Content } = Layout;

export function ChainSubscribeContent(props: {
  cancelBtnText: string;
  cancelBtnClick: () => void;
  submitBtnText: string;
}) {
  const queryParams = useQueryParams<{
    chainId: string;
    chainName: string;
  }>();
  const gotoOrganization = useGotoClick('/certificates?tab=organization');
  const gotoNode = useGotoClick('/certificates?tab=node');
  const gotoUser = useGotoClick('/certificates?tab=user');
  const { orgList, run: fetchOrgList } = useFetchCertOrgList();
  const { userList, run: fetchCertUserList } = useFetchCertUserList();
  const {
    control,
    setValue,
    trigger,
    reset,
    formState: { isValidating, isSubmitted, isValid },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Tls: true,
    } as any,
  });
  const [isPopulatedConfig, setIsPopulatedConfig] = useState(false); // 标记是否是拉取指定chainId的配置
  const selectedOrgId = useWatch({ name: 'OrgId', control });
  const { run: fetchChainSubscribe } = useChainSubscribe();
  const goChains = useGotoClick('/chains');
  const handleSaveClick = useCallback(() => {
    const values = {
      ...getValues(),
      Tls: getValues().Tls ? 0 : 1,
    } as unknown as ChainSubscribeRequest;
    fetchChainSubscribe(values).then(() => {
      goChains();
    });
  }, []);

  const { run: fetchSubscribeConfig, data: chainConfig } = useFetchChainSubscribeConfig();

  useEffect(() => {
    fetchCertUserList(selectedOrgId);
    if (!isPopulatedConfig) {
      setValue('UserName', null);
      trigger();
    }
    setIsPopulatedConfig(false);
  }, [selectedOrgId]);

  useEffect(() => {
    fetchOrgList();
  }, []);

  useEffect(() => {
    if (queryParams.chainId) {
      fetchSubscribeConfig(queryParams.chainId);
    }
  }, [queryParams]);

  useEffect(() => {
    if (chainConfig) {
      setIsPopulatedConfig(true);
      reset({
        ...omit(chainConfig, ['OrgName']),
        Tls: !chainConfig.Tls,
        OrgId: orgList.find((item) => item.OrgName === chainConfig.OrgName)?.OrgId,
      });
    }
  }, [chainConfig]);

  return (
    <>
      <Row>
        <Col span={12}>
          <Alert>
            <List type="number">
              <List.Item>
                本处配置的节点，将用于监听链的信息，以及往链上发送交易，请配置该链上的节点，并确保稳定运行。
              </List.Item>
              <List.Item>
                本处配置的用户，将在后续往链上发送交易时，用于交易签名，请配置该链上的用户，以确保签名有效。
              </List.Item>
              <List.Item>
                若您要监听的链的证书不是通过本平台生成的，则请先导入相关
                <Button className={'text-deep-blue'} type={'link'} onClick={gotoOrganization}>
                  组织证书
                </Button>
                ，
                <Button className={'text-deep-blue'} type={'link'} onClick={gotoNode}>
                  节点证书
                </Button>
                ，
                <Button className={'text-deep-blue'} type={'link'} onClick={gotoUser}>
                  用户证书
                </Button>
                等，再配置。
              </List.Item>
            </List>
          </Alert>
          <Form>
            <Controller
              control={control}
              rules={{
                validate: Validator.validateChainId,
              }}
              name="ChainId"
              render={({ field, fieldState }) => (
                <Form.Item
                  label="链ID"
                  required
                  status={formUtils.getStatus({
                    fieldState,
                    isValidating,
                    isSubmitted,
                  })}
                  message={fieldState.error?.message}
                >
                  <Input autoComplete="off" placeholder="请输入链ID" {...field} />
                </Form.Item>
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="OrgId"
              render={({ field, fieldState }) => (
                <Form.Item
                  status={formUtils.getStatus({
                    fieldState,
                    isValidating,
                    isSubmitted,
                  })}
                  required
                  label="组织名称"
                >
                  <Select
                    options={orgList.map((item) => ({
                      text: item.OrgName,
                      value: item.OrgId,
                    }))}
                    {...field}
                  />
                </Form.Item>
              )}
            />
            <Controller
              control={control}
              rules={{
                validate: Validator.validateNodeIP,
              }}
              name="NodeRpcAddress"
              render={({ field, fieldState }) => (
                <Form.Item
                  label="节点RPC地址"
                  required
                  status={formUtils.getStatus({
                    fieldState,
                    isValidating,
                    isSubmitted,
                  })}
                  message={fieldState.error?.message}
                >
                  <Input autoComplete="off" placeholder="请输入所选节点部署的IP和端口" {...field} />
                </Form.Item>
              )}
            />
            <Controller
              control={control}
              name="UserName"
              rules={{
                required: true,
                validate: (value: string) => {
                  if (!value?.length) {
                    return '请选择用户';
                  }
                  return undefined;
                },
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  required
                  status={formUtils.getStatus({
                    fieldState,
                    isValidating,
                    isSubmitted,
                  })}
                  label="用户名称"
                >
                  <Select
                    options={userList.map((item) => ({ text: item.UserName, value: item.UserName }))}
                    {...field}
                  />
                </Form.Item>
              )}
            />{' '}
            <Controller
              control={control}
              name="Tls"
              render={({ field }) => (
                <Form.Item label={<GlossaryGuide title={'是否开启TLS'} />}>
                  <Switch {...field} />
                </Form.Item>
              )}
            />
          </Form>
          <Justify
            className={'tea-mt-5n'}
            left={
              <>
                <Button onClick={props.cancelBtnClick}>{props.cancelBtnText}</Button>
                <Button type="primary" onClick={handleSaveClick} disabled={!isValid}>
                  {props.submitBtnText}
                </Button>
              </>
            }
          />
        </Col>
        <Col span={12}>
          <Alert.Notice defaultOpen title={'常见问题'}>
            <List type="number">
              <List.Item>如果发现管理平台区块高度落后于链的情况，请重新订阅。</List.Item>
              <List.Item>
                订阅链失败，与节点连接失败，请检查节点端口是否开放”，则检查以下几点：
                <List type="bullet">
                  <List.Item>链节点端口是否开放。</List.Item>
                  <List.Item>所订阅的链的节点部署的ip和端口是否和生成节点证书时填写的一致。</List.Item>
                  <List.Item>链是否为容器启动，容器启动的链不能用容器启动的管理台订阅，因为网络是不通的。</List.Item>
                </List>
              </List.Item>
              <List.Item>
                订阅链失败，证书错误，请检查用户证书是否正确：
                <List type="bullet">
                  <List.Item>
                    一般导入外部链容易出现，请检查是否全部证书导入正常，是否和链使用的证书为同一套。
                  </List.Item>
                </List>
              </List.Item>

              <List.Item>
                订阅链失败，tls握手失败，请检查证书是否正确：
                <List type="bullet">
                  <List.Item>一般导入外部链容易出现，请检查TLS证书是否正确导入，是否是订阅组织下的用户。</List.Item>
                </List>
              </List.Item>
              <List.Item>
                订阅链失败，chainId错误，请检查chainId是否正确：
                <List type="bullet">
                  <List.Item>一般导入外部链容易出现，请检查订阅的输入chainid和外部链是否一致。</List.Item>
                </List>
              </List.Item>
            </List>
          </Alert.Notice>
        </Col>
      </Row>
    </>
  );
}

export default function ChainSubscribe() {
  const handleBackClick = useGotoClick('/chains');
  return (
    <Content>
      <Content.Header title="区块链管理/订阅链" showBackButton onBackButtonClick={handleBackClick} />
      <Content.Body full>
        <Card>
          <Card.Header>
            <H3>配置监控节点</H3>
          </Card.Header>
          <Card.Body>
            <ChainSubscribeContent cancelBtnText={'取消'} submitBtnText={'保存'} cancelBtnClick={handleBackClick} />
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  );
}
