/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import {
  Button,
  Card,
  Form,
  H3,
  Icon,
  Input,
  InputNumber,
  Justify,
  Layout,
  List,
  Radio,
  RadioGroup,
  Select,
  Stepper,
  Text,
} from 'tea-component';
import React, { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useArrayRef, useGotoClick, useQueryParams } from '../../utils/hooks';
import { Control, Controller, useForm, useWatch } from 'react-hook-form';
import formUtils, { getStatus, Validator } from '../../utils/form-utils';
import { ChainCreateRequest } from '../../common/apis/chains/interface';
import { flatOptions, SelectedNodes, SourceSelector, TargetSelector } from './selector';
import {
  useChainCreate,
  useFetchCertNodeList,
  useFetchCertOrgList,
  useFetchChainConsensusList,
  useFetchChainDetail,
  useFetchChainDownload,
} from '../../common/apis/chains/hooks';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { useHistory } from 'react-router-dom';
import GlossaryGuide from '../../common/components/glossary-guide';
import { showChainAgreement } from './chain-agreement';
import { Checkbox, Switch } from 'tea-component/es';
import hljs from 'highlight.js';
import { Dictionary, keyBy, omit, stringifyQueryParams } from '../../utils/common';
import { ChainSubscribeContent } from './chain-subscribe';
import { renderChainAlgorithmFormItem } from '../certificates/organization-cert/modals/import-org-cert-modal';
import { ChainAlgorithm } from '../../common/apis/certificates/interface';

const { Content } = Layout;
type ChainNewStep = 'deploy' | 'config' | 'subscribe';
type ChainNewQueryParam = {
  chainId: string;
  chainName: string;
  step: ChainNewStep;
};

const steps: { id: ChainNewStep; label: string }[] = [
  { id: 'config', label: '链参数配置' },
  { id: 'deploy', label: '部署轮奂链' },
  { id: 'subscribe', label: '订阅轮奂链' },
];

export default function ChainNew() {
  const handleBackClick = useGotoClick('/chains');
  const chainQueryParam = useQueryParams<ChainNewQueryParam>();
  const history = useHistory();
  const [current, setCurrent] = useState<ChainNewStep>(chainQueryParam.step || 'config');
  const onNext = useCallback((values: ChainNewQueryParam) => {
    history.push(`/chains/new?${stringifyQueryParams(values)}`);
  }, []);

  useEffect(() => {
    setCurrent(chainQueryParam.step || 'config');
  }, [chainQueryParam]);

  return (
    <>
      <Content.Header title="区块链管理/新建区块链" showBackButton onBackButtonClick={handleBackClick} />
      <Content.Body full>
        <Card>
          <Card.Body>
            <Justify className={'content-mb-4n'} left={<Stepper steps={steps} current={current} />} />
            {current === 'config' && <ChainConfig onNext={onNext} />}
            {current === 'deploy' && <ChainDeploy onNext={onNext} />}
            {current === 'subscribe' && <ChainSubscribe />}
          </Card.Body>
        </Card>
      </Content.Body>
    </>
  );
}

type ChainNode = ChainCreateRequest['Nodes'][0]['NodeList'][0];

function ChainConfig(props: { onNext: (values: ChainNewQueryParam) => void }) {
  const {
    control,
    setValue,
    trigger,
    formState: { isValidating, isSubmitted, isValid },
    getValues,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      Tls: true,
      DockerVm: VM_OPTIONS.filter((item) => item.checked).map((item) => item.name),
      Monitor: '0',
      ChainmakerImprove: '1',
      Algorithm: ChainAlgorithm.NOT_CN.toString(),
    } as any,
  });
  const deployFormRef = useRef<any>();
  const handleNextClick = useCallback(() => {
    const values = omit(getValues(), ['_Nodes', 'Algorithm']) as ChainCreateRequest;
    onNext({
      ...values,
      DockerVm: (getValues('DockerVm') as string[]).find((item) => item === 'DOCKER_GO') ? 1 : 0,
      BlockTxCapacity: values.BlockTxCapacity ?? Validator.minBlockTxCapacity,
      BlockInterval: values.BlockInterval ?? Validator.minBlockInterval,
      TxTimeout: values.TxTimeout ?? Validator.minTxTimeout,
      Consensus: +values.Consensus,
      Tls: values.Tls ? 0 : 1,
      Monitor: +getValues('Monitor') as any,
      ChainmakerImprove: +getValues('ChainmakerImprove') as any,
      ...deployFormRef.current.getValues(),
    });
  }, []);
  const goBack = useGotoClick('/chains');

  const { list: consensusList, run: fetchConsensusList } = useFetchChainConsensusList();

  const consensusOptions = useMemo(
    () => consensusList.map((item) => ({ value: String(item.ConsensusType), text: item.ConsensusName })),
    [consensusList],
  );

  const { orgList: certOrgList, run: fetchOrgList } = useFetchCertOrgList();
  const { nodeList, run: fetchNodeList } = useFetchCertNodeList();
  // 穿梭框选中的节点

  const [selectedNodesEmptyContains, setSelectedNodesEmptyContains] = useState<SelectedNodes>([]); // 单个组织下节点信息会有空长度节点列表
  // 默认获取第一个组织的所有节点

  const [selectedOrgIndex, setSelectedOrgIndex] = useState(0);
  const [selectorOptions, setSelectorOptions] = useState<SelectedNodes>([]);
  const sourceSelectorRef = useRef<any>();
  const selectedConsensus = useWatch({ name: 'Consensus', control });
  const selectedAlgorithm = useWatch({ name: 'Algorithm', control });
  const selectedNodes: ChainCreateRequest['Nodes'] = useWatch({ name: '_Nodes', control });
  const { run } = useChainCreate();
  const onNext = useCallback(
    async (values: ChainCreateRequest) => {
      run(values)
        .then(() => {
          props.onNext({
            chainId: values.ChainId,
            chainName: values.ChainName,
            step: 'deploy',
          });
        })
        .catch(() => null);
    },
    [props],
  );

  useEffect(() => {
    fetchConsensusList();
  }, []);

  useEffect(() => {
    fetchOrgList('', +selectedAlgorithm as any);
    setSelectorOptions([]);
    setSelectedNodesEmptyContains([]);
    sourceSelectorRef.current.updateSelectedNodes([]);
    setValue('_Nodes', []);
  }, [selectedAlgorithm]);

  useEffect(() => {
    if (certOrgList.length === 0) {
      return;
    }
    fetchNodeList(certOrgList[selectedOrgIndex].OrgId);
    setSelectorOptions(
      certOrgList.map((item) => ({
        text: item.OrgName,
        value: item.OrgId,
        options: [],
      })),
    );
  }, [certOrgList]);

  useEffect(() => {
    if (nodeList.length === 0) {
      return;
    }
    selectorOptions[selectedOrgIndex].options = nodeList.map((item) => ({
      text: item.NodeName,
      value: item.NodeName,
    }));
    setSelectorOptions([...selectorOptions]);
  }, [nodeList]);

  useEffect(() => {
    // 避免重新点击再次请求
    if (selectorOptions[selectedOrgIndex]?.options.length === 0) {
      fetchNodeList(certOrgList[selectedOrgIndex].OrgId);
    }
  }, [selectedOrgIndex]);

  useEffect(() => {
    trigger('_Nodes');
  }, [selectedConsensus]);

  return (
    <>
      <Justify className={'tea-mt-5n'} left={<H3>基础信息</H3>} />
      <Form className={'tea-mt-5n tea-mb-5n'}>
        <Controller
          control={control}
          rules={{
            validate: Validator.validateChainId,
          }}
          name="ChainId"
          render={({ field, fieldState }) => (
            <Form.Item
              label={<GlossaryGuide title={'区块链ID'} />}
              required
              status={formUtils.getStatus({
                fieldState,
                isValidating,
                isSubmitted,
              })}
              message={fieldState.error?.message}
            >
              <Input autoComplete="off" placeholder="请输入区块链ID" {...field} />
            </Form.Item>
          )}
        />
        <Controller
          control={control}
          rules={{
            validate: Validator.validateChainName,
          }}
          name="ChainName"
          render={({ field, fieldState }) => (
            <Form.Item
              status={formUtils.getStatus({
                fieldState,
                isValidating,
                isSubmitted,
              })}
              required
              label="区块链名称"
              message={fieldState.error?.message}
            >
              <Input placeholder="请输入区块链名称" {...field} autoComplete="off" />
            </Form.Item>
          )}
        />

        <Controller
          control={control}
          name="BlockTxCapacity"
          render={({ field, fieldState }) => (
            <Form.Item
              required
              status={formUtils.getStatus({
                fieldState,
                isValidating,
                isSubmitted,
              })}
              label={<GlossaryGuide title={'区块最大容量'} />}
              message={fieldState.error?.message}
            >
              <InputNumber
                min={Validator.minBlockTxCapacity}
                max={Validator.maxNumber}
                unit={'笔交易'}
                size={'l'}
                {...field}
              />
            </Form.Item>
          )}
        />
        <Controller
          control={control}
          name="BlockInterval"
          render={({ field, fieldState }) => (
            <Form.Item
              required
              status={formUtils.getStatus({
                fieldState,
                isValidating,
                isSubmitted,
              })}
              label={<GlossaryGuide title={'出块间隔'} />}
            >
              <InputNumber
                min={Validator.minBlockInterval}
                max={Validator.maxNumber}
                unit={'ms'}
                size={'l'}
                {...field}
              />
            </Form.Item>
          )}
        />
        <Controller
          control={control}
          name="TxTimeout"
          render={({ field, fieldState }) => (
            <Form.Item
              required
              status={formUtils.getStatus({
                fieldState,
                isValidating,
                isSubmitted,
              })}
              label={<GlossaryGuide title={'交易过期时长'} />}
            >
              <InputNumber min={Validator.minTxTimeout} max={Validator.maxNumber} unit={'s'} size={'l'} {...field} />
            </Form.Item>
          )}
        />
        {renderChainAlgorithmFormItem(control, isSubmitted, isValidating, false, 'm')}
        <Controller
          control={control}
          name="Tls"
          render={({ field }) => (
            <Form.Item label={<GlossaryGuide title={'是否开启TLS'} />}>
              <Switch {...field} />
            </Form.Item>
          )}
        />
        <Justify left={<H3>共识信息</H3>} className={'tea-mb-5n'} />
        <Controller
          control={control}
          name="Consensus"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              required
              status={formUtils.getStatus({
                fieldState,
                isValidating,
                isSubmitted,
              })}
              label={<GlossaryGuide title={'共识策略'} />}
              className={'tea-mt-1n'}
            >
              <Select options={consensusOptions} {...field} />
            </Form.Item>
          )}
        />
        <Controller
          control={control}
          name={'_Nodes'} // 临时属性，该字段并不提交后台
          defaultValue={[]}
          rules={{
            validate: (value: ChainCreateRequest['Nodes']) => {
              const nodes = value.reduce((res: ChainNode[], item) => {
                if (item.NodeList) {
                  return res.concat(item.NodeList);
                }
                return res;
              }, []);
              if (nodes.length === 0) {
                return '请选择共识节点';
              }
              if (getValues('Consensus') === '0' && nodes.length !== 1) {
                return '共识为SOLO则节点只能选择1个';
              }
              if (getValues('Consensus') === '4' && nodes.length < 3) {
                return '共识为RAFT则节点至少选择3个';
              }

              if (['1', '3'].indexOf(getValues('Consensus')) > -1 && nodes.length < 4) {
                return '共识为TBFT、HOTSUFF则至少需要选择4个节点';
              }
              return undefined;
            },
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              required
              label="共识节点"
              className={'node-selector'}
              status={formUtils.getStatus({ fieldState, isSubmitted, isValidating })}
              message={fieldState.error?.message}
            >
              <div className={'flex'}>
                <SourceSelector
                  title="选择节点"
                  data={{ title: '组织名', subTitle: '节点名称', options: selectorOptions }}
                  onChange={(values) => {
                    setSelectedNodesEmptyContains(values);
                    UpdateFormItemForNode(setValue, field, values);
                  }}
                  onLeftOptionClick={(index) => {
                    setSelectedOrgIndex(index);
                  }}
                  ref={sourceSelectorRef}
                />
                <TargetSelector
                  title={`已选择(${flatOptions(selectedNodesEmptyContains).length})`}
                  data={{ title: '组织名', subTitle: '节点名称', options: selectedNodesEmptyContains }}
                  onChange={(values) => {
                    setSelectedNodesEmptyContains(values);
                    sourceSelectorRef.current.updateSelectedNodes(values);
                    UpdateFormItemForNode(setValue, field, values);
                  }}
                />
              </div>
            </Form.Item>
          )}
        />
      </Form>
      <ChainDeployConfig ref={deployFormRef} selectedNodes={selectedNodes} control={control} trigger={trigger} />
      <ChainVmConfig control={control} />
      <ChainLogConfig control={control} />
      <Justify
        className={'tea-mt-5n'}
        left={
          <>
            <Button onClick={goBack}>取消</Button>
            <Button type="primary" onClick={handleNextClick} disabled={!isValid}>
              下一步
            </Button>
          </>
        }
      />
    </>
  );
}

const shell_1 = hljs.highlight('$ docker pull chainmakerofficial/chainmaker-vm-docker-go:v2.2.0.1', {
  language: 'shell',
}).value;

const shell_2 = hljs.highlight(
  '$ cd /usr/local/src\n' +
    '\n' +
    '$ wget -c http://mirrors.kernel.org/fedora-epel/epel-release-latest-7.noarch.rpm\n' +
    '\n' +
    '$ rpm -ivh epel-release-latest-7.noarch.rpm\n' +
    '\n' +
    '$ yum repolist #查看是否启用\n' +
    '\n' +
    '$ yum install -y p7zip*',
  { language: 'shell' },
).value;

const shell_3 = hljs.highlight('$ sudo apt-get install p7zip', { language: 'shell' }).value;

const shell_4 = hljs.highlight('$ cd release\n$ ./start.sh', { language: 'shell' }).value;

function ChainDeploy(props: { onNext: (values: ChainNewQueryParam) => void }) {
  const chainParams = useQueryParams<ChainNewQueryParam>();
  const { run, loading } = useFetchChainDownload();
  const { run: fetchChain, chain } = useFetchChainDetail();

  const handleDownloadClick = useCallback(() => {
    run(chainParams.chainId);
  }, []);

  const onNext = useCallback(() => {
    props.onNext({
      ...chainParams,
      step: 'subscribe',
    });
  }, [props]);

  useEffect(() => {
    fetchChain(chainParams.chainId);
  }, []);

  return (
    <>
      <Justify left={<H3>下载配置文件</H3>} className={'tea-mt-5n'} />
      <Form className={'tea-mt-5n'}>
        <Form.Item label={'链配置文件'}>
          <Text reset theme="text">
            {chainParams.chainName}.zip
            <Button type={'link'} onClick={handleDownloadClick} className={'tea-ml-5n'} disabled={loading}>
              <img src={loading ? 'static/icon/download_disabled.svg' : 'static/icon/download.svg'} />
              下载配置文件
            </Button>
            {loading && <Icon type="loading" />}
          </Text>
        </Form.Item>
      </Form>
      <Justify left={<H3>部署链教程</H3>} className={'tea-mt-5n'} />
      <List type="number" className={'tea-mt-5n'}>
        {chain?.DockerVm === 1 && (
          <List.Item>
            <div className={'tea-text-danger'}>
              如希望部署的链支持docker_go合约，则需要先拉取chainmaker-vm-docker-go镜像，以及安装7z工具，如果不需要docker-go合约则忽略此步骤。
            </div>
            <List type="bullet">
              <List.Item>
                拉取chainmaker-vm-docker-go镜像
                <pre
                  className={'code chain-code'}
                  dangerouslySetInnerHTML={{
                    __html: shell_1,
                  }}
                ></pre>
              </List.Item>
              <List.Item>
                CentOS系统安装7z工具
                <pre
                  className={'code chain-code'}
                  dangerouslySetInnerHTML={{
                    __html: shell_2,
                  }}
                ></pre>
              </List.Item>
              <List.Item>
                Ubuntu系统安装7z工具
                <pre
                  className={'code chain-code'}
                  dangerouslySetInnerHTML={{
                    __html: shell_3,
                  }}
                ></pre>
              </List.Item>
            </List>
          </List.Item>
        )}
        <List.Item>下载链配置文件压缩包</List.Item>
        <List.Item>
          单机部署的话，将压缩包移动到要部署的Linux系统机器上，解压压缩包，执行release
          目录下的start.sh脚本即可启动链，命令如下
          <p className={'tea-mt-2n'}>
            <pre
              className={'code chain-code'}
              dangerouslySetInnerHTML={{
                __html: shell_4,
              }}
            ></pre>
          </p>
        </List.Item>
        <List.Item>
          多机部署的话，则需要分别在要部署的机器上执行上述操作，直至全部节点被成功启动，才算部署完成。注意各机器间需要保证网络通畅，才能完成节点P2P组网。
        </List.Item>
        <List.Item>成功部署区块链后，点击下一步，用管理台订阅已部署的链。</List.Item>
      </List>
      <Justify
        className={'tea-mt-5n'}
        left={
          <Button type="primary" onClick={onNext}>
            下一步
          </Button>
        }
      />
    </>
  );
}

function UpdateFormItemForNode(
  setValue: UseFormSetValue<FieldValues>,
  field: ControllerRenderProps<FieldValues>,
  values: SelectedNodes,
) {
  const newValue = values
    .filter((item) => item.options.length > 0)
    .map((item) => ({
      OrgId: item.value,
      NodeList: item.options.map((node) => ({
        NodeName: node.value,
      })),
    }));
  setValue(field.name, newValue, { shouldValidate: true, shouldDirty: true });
}

function ChainLogConfig({ control }: { control: Control }) {
  const showAgreementClick = useCallback(() => {
    showChainAgreement();
  }, []);

  const monitorWatch = useWatch({ name: 'Monitor', control });

  return (
    <>
      <Justify
        className={'tea-mt-5n'}
        left={
          <H3>
            <GlossaryGuide title={'报错日志采集服务（可选项）'} />
          </H3>
        }
      />
      <Form className={'content-mt-2n'}>
        <Controller
          control={control}
          name="Monitor"
          rules={{}}
          render={({ field }) => (
            <Form.Item label={<GlossaryGuide title={'监控开关'} />} className={'tea-mt-1n'}>
              <ChainRadioGroup field={field} />
              <div className={'content-mt-1n'}>
                <Form.Text>
                  <Text>
                    请您先阅读
                    <Button type={'link'} onClick={showAgreementClick}>
                      《chainmaker管理台数据收集声明协议》
                    </Button>
                    再确定是否开启,开启监控开关即表示您同意本协议
                  </Text>
                </Form.Text>
              </div>
            </Form.Item>
          )}
        />
        {monitorWatch === '1' && (
          <>
            <Controller
              control={control}
              name="ChainmakerImprove"
              rules={{}}
              render={({ field }) => (
                <Form.Item label={<GlossaryGuide title={'是否参加轮奂链改进计划'} />} className={'tea-mt-1n'}>
                  <ChainRadioGroup field={field} />
                </Form.Item>
              )}
            />
          </>
        )}
      </Form>
    </>
  );
}

function ChainRadioGroup({ field }: { field: ControllerRenderProps }) {
  return (
    <RadioGroup {...field}>
      <Radio name="1">开启</Radio>
      <Radio name="0">关闭</Radio>
    </RadioGroup>
  );
}

const VM_OPTIONS: { name: string; title?: string; disabled: boolean; checked: boolean }[] = [
  {
    name: 'DOCKER_GO',
    disabled: false,
    checked: false,
  },
  {
    name: 'WASMER',
    disabled: true,
    checked: true,
  },
  {
    name: 'EVM',
    disabled: true,
    checked: true,
  },
  {
    name: 'WXVM',
    disabled: true,
    checked: true,
  },
  {
    name: 'GASM',
    disabled: true,
    checked: true,
  },
];

const DEPLOY_OPTIONS: typeof VM_OPTIONS = [
  {
    name: '0',
    title: '单机部署',
    disabled: false,
    checked: true,
  },
  {
    name: '1',
    title: '多机部署',
    disabled: false,
    checked: false,
  },
];

function ChainDeployConfigContainer(
  props: {
    selectedNodes: ChainCreateRequest['Nodes'];
    control: Control;
    trigger: UseFormTrigger<any>;
  },
  ref: Ref<any>,
) {
  const { control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      Single: '0',
    } as any,
  });
  const deployMode = useWatch({ name: 'Single', control });
  const [nodeMap, setNodeMap] = useState<Dictionary<ChainNode>>({});
  const [configRowRefs] = useArrayRef();
  useEffect(() => {
    // 组织节点树转化为节点列表
    const nodes = (props.selectedNodes || []).reduce((res: ChainNode[], item) => res.concat(...item.NodeList), []);
    const newNodeMap = keyBy(nodes, 'NodeName');

    /**
     * 需要去掉已删除节点的同时，保留还存在的节点的输入值，同时既存节点的输入顺序为主
     */
    setNodeMap((oldNodesMap: any) => {
      Object.keys(oldNodesMap).forEach((k: string) => {
        if (!newNodeMap[k]) {
          delete oldNodesMap[k];
        }
      });
      Object.keys(newNodeMap).forEach((k: string) => {
        if (oldNodesMap[k]) {
          delete newNodeMap[k];
        }
      });
      return {
        ...oldNodesMap,
        ...newNodeMap,
      };
    });
  }, [props.selectedNodes]);

  useImperativeHandle(
    ref,
    () => ({
      getValues: () => {
        const map = configRowRefs.reduce((res, ref, index) => {
          const node = ref.getValues();
          if (deployMode === DEPLOY_OPTIONS[0].name && index > 0) {
            node.NodeIp = configRowRefs[0].getValues().NodeIp;
          }
          res[node.NodeName] = node;
          return res;
        }, {});
        return {
          Nodes: props.selectedNodes.map((item) => ({
            ...item,
            NodeList: item.NodeList.map((item) => map[item.NodeName]),
          })),
          Single: +deployMode,
        };
      },
    }),
    [nodeMap, configRowRefs, deployMode],
  );

  return (
    <Controller
      control={props.control}
      name={'Nodes'}
      rules={{
        validate: () => {
          if (configRowRefs.every((item) => item.isValid)) {
            return;
          }
          return '请填写相关配置';
        },
      }}
      render={() => (
        <div>
          <Justify
            className={'tea-mt-5n'}
            left={
              <H3>
                <GlossaryGuide title={'节点部署配置'} />
              </H3>
            }
          />
          <Form className={'content-mt-2n content-mb-2n'}>
            <Controller
              control={control}
              name="Single"
              render={({ field }) => (
                <Form.Item label={<GlossaryGuide title={'部署方式'} />} className={'tea-mt-1n'}>
                  <RadioGroup {...field}>
                    {DEPLOY_OPTIONS.map((item) => (
                      <Radio key={item.name} name={item.name}>
                        {item.title}
                      </Radio>
                    ))}
                  </RadioGroup>
                </Form.Item>
              )}
            />
          </Form>
          {Object.values(nodeMap).map((item, index) => (
            <ChainNodeConfigRow
              index={index}
              node={item}
              deployMode={deployMode}
              key={item.NodeName}
              onValidChange={() => props.trigger('Nodes')}
              ref={(ref) => (configRowRefs[index] = ref)}
            />
          ))}
        </div>
      )}
    />
  );
}

function ChainNodeConfigRowContainer(
  {
    index,
    deployMode,
    node: item,
    onValidChange,
  }: {
    index: number;
    node: ChainNode;
    deployMode: string;
    onValidChange: (isValid: boolean) => void;
  },
  ref: any,
) {
  const {
    control,
    getValues,
    formState: { isValidating, isSubmitted, isValid },
  } = useForm({
    mode: 'onBlur',
  });
  /**
   * 单机模式下，只需要在第一个节点输入IP即可
   */
  const ipInputDisabled = deployMode === DEPLOY_OPTIONS[0].name && index > 0;

  useImperativeHandle(ref, () => {
    const values = getValues();
    return {
      isValid,
      getValues: () => ({
        NodeName: item.NodeName,
        NodeRpcPort: +values.NodeRpcPort,
        NodeP2pPort: +values.NodeP2pPort,
        NodeIp: values.NodeIp,
      }),
    };
  });

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid]);

  return (
    <Form layout={'inline'} key={item.NodeName}>
      <Form.Item key={item.NodeName} label={<div className={'chain-deploy-node-name'}>{item.NodeName}</div>} />

      <Controller
        name={'NodeIp'}
        control={control}
        rules={{
          validate: (input) => {
            if (ipInputDisabled) {
              return undefined;
            }
            return Validator.validateIP(input);
          },
        }}
        render={({ field, fieldState }) => (
          <Form.Item
            key={item.NodeName}
            status={getStatus({
              fieldState,
              isSubmitted,
              isValidating,
            })}
            message={fieldState.error?.message}
          >
            <Input placeholder={ipInputDisabled ? '同上' : 'IP地址'} disabled={ipInputDisabled} {...field} />
          </Form.Item>
        )}
      />
      <Controller
        control={control}
        rules={{
          validate: (input: string) => {
            if (input && getValues('NodeP2pPort') === input) {
              return 'RPC端口与P2P端口不可以相同';
            }
            return Validator.validatePort(input);
          },
        }}
        render={({ field, fieldState }) => (
          <Form.Item
            status={getStatus({ fieldState, isSubmitted, isValidating })}
            className={'tea-ml-2n'}
            message={fieldState.error?.message}
          >
            <Input placeholder={'RPC端口'} {...field} />
          </Form.Item>
        )}
        name={'NodeRpcPort'}
      />
      <Controller
        control={control}
        rules={{
          validate: (input: string) => {
            if (input && getValues('NodeRpcPort') === input) {
              return 'RPC端口与P2P端口不可以相同';
            }
            return Validator.validatePort(input);
          },
        }}
        render={({ field, fieldState }) => (
          <Form.Item
            status={getStatus({ fieldState, isSubmitted, isValidating })}
            className={'tea-ml-2n'}
            message={fieldState.error?.message}
          >
            <Input placeholder={'P2P端口'} {...field} />
          </Form.Item>
        )}
        name={'NodeP2pPort'}
      />
    </Form>
  );
}

const ChainNodeConfigRow = React.forwardRef(ChainNodeConfigRowContainer);

const ChainDeployConfig = React.forwardRef(ChainDeployConfigContainer);

function ChainVmConfigContainer({ control }: { control: Control }) {
  return (
    <div>
      <Justify
        className={'tea-mt-5n'}
        left={
          <H3>
            <GlossaryGuide title={'合约虚拟机配置'} />
          </H3>
        }
      />
      <Form className={'content-mt-2n'}>
        <Controller
          control={control}
          name="DockerVm"
          rules={{}}
          render={({ field }) => (
            <Form.Item label={'支持的虚拟机类型'} className={'tea-mt-1n'}>
              <Checkbox.Group {...field}>
                {VM_OPTIONS.map((item) => (
                  <Checkbox key={item.name} name={item.name} disabled={item.disabled}>
                    {item.name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          )}
        />
        <div className={'tea-form__item text-size-base'}>
          <div className={'tea-form__label'} />
          <div
            className={'text-pre-line'}
            dangerouslySetInnerHTML={{
              __html: `默认支持WASMER、EVM、WXVM、GASM，支持Rust、Solidity，C++语言的合约。\n如果您想部署Go语言的合约的话，需要勾选支持Docker_go，勾选后在部署链时除链的主程序外，还会再额外部署一个DockerVM。`,
            }}
          />
        </div>
      </Form>
    </div>
  );
}

const ChainVmConfig = ChainVmConfigContainer;

function ChainSubscribe() {
  const queryParams = useQueryParams<ChainNewQueryParam>();
  const history = useHistory();
  const goBack = useCallback(() => {
    history.push(
      `/chains/new?${stringifyQueryParams({
        ...queryParams,
        step: 'deploy',
      })}`,
    );
  }, [queryParams]);
  return (
    <>
      <ChainSubscribeContent cancelBtnText={'上一步'} submitBtnText={'确认订阅'} cancelBtnClick={goBack} />
    </>
  );
}
