/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { AxiosRequestConfig } from 'axios';
import { $axios } from 'src/utils/request';

export function callApi(cmb: string, params?: any, otherConfig?: Omit<AxiosRequestConfig, 'params' | 'data'>) {
  return $axios({
    params: { cmb },
    data: params,
    ...(otherConfig || {}),
  });
}

