/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Input, Table, TableColumn } from 'tea-component';
import { useFetchDealDetail } from 'src/common/apis/chains/hooks';
import { formatDate } from 'src/utils/date';
import { myWorker } from '../../../App';
import { WebWorkerResponse } from '../../../worker';
import { IChainBrowserTxDetail } from '../../../common/apis/chains/interface';

const { groupable, autotip } = Table.addons;
const { TextArea } = Input;


interface IParamListItem {
  Id: number;
  key: string;
  value: string;
  fullData?: string;
}

type Props = {
  id?: number;
  chainId: string | null;
  txId?: string;
};

export function DealDetail({ id, chainId, txId }: Props): JSX.Element {
  const { detail, fetch } = useFetchDealDetail();
  const [paramsList, setParamsList] = useState<IParamListItem[]>([]);
  const [contractResultDecode, setContractResultDecode] = useState<string>();
  const defaultColumn: TableColumn<IParamListItem>[] = [
    {
      key: 'Id',
      header: '#',
      width: 100,
      // eslint-disable-next-line react/display-name
      render: ({ Id }: IParamListItem) => <>{Id}</>,
    },
    {
      key: 'key',
      header: 'Key',
    },
    {
      key: 'value',
      header: 'Value',
    },
  ];

  useEffect(() => {
    if (!chainId) {
      return;
    }
    if (!id && !txId) {
      return;
    }
    fetch({
      ChainId: chainId,
      Id: id,
      TxId: txId,
    });
  }, [fetch]);

  useEffect(() => {
    if (detail) {
      myWorker.postMessage(JSON.stringify({
        type: 'deal-detail',
        data: detail,
      }));
      myWorker.onmessage = ({ data }: { data: WebWorkerResponse<IChainBrowserTxDetail> }) => {
        setParamsList(
          data.data.ContractParameters?.map((param, idx) => ({
            Id: idx,
            key: param.key,
            value: param.value,
          })),
        );
        setContractResultDecode(data.data.ContractResult);
      };
    }
  }, [detail]);

  return (
    <>
      <Card>
        <Card bordered style={{ border: 'none' }}>
          <Card.Body title="所属区块信息">
            <Form>
              <Form.Item label="区块哈希">
                <Form.Text>{detail?.BlockHash}</Form.Text>
              </Form.Item>
              <Form.Item label="区块高度">
                <Form.Text>{detail?.BlockHeight}</Form.Text>
              </Form.Item>
            </Form>
          </Card.Body>
        </Card>

        <Card bordered style={{ border: 'none' }} className="tea-mt-0">
          <Card.Body title="交易信息">
            <Form>
              <Form.Item label="交易ID">
                <Form.Text>{detail?.TxId}</Form.Text>
              </Form.Item>
              <Form.Item label="交易状态">
                <Form.Text>{detail?.TxStatusCode}</Form.Text>
              </Form.Item>
              <Form.Item label="交易发起组织">
                <Form.Text>{detail?.OrgName}</Form.Text>
              </Form.Item>
              <Form.Item label="交易发起用户">
                <Form.Text>{detail?.UserName}</Form.Text>
              </Form.Item>
              <Form.Item label="交易发起时间">
                <Form.Text>{formatDate(detail?.Timestamp)}</Form.Text>
              </Form.Item>
              <Form.Item label="交易类型">
                <Form.Text>{detail?.TxType}</Form.Text>
              </Form.Item>
            </Form>
          </Card.Body>
        </Card>

        <Card bordered style={{ border: 'none' }} className="tea-mt-0">
          <Card.Body title="合约执行信息">
            <Form>
              <Form.Item label="所属合约">
                <Form.Text>{detail?.ContractName}</Form.Text>
              </Form.Item>
              <Form.Item label="消耗GAS量">
                <Form.Text>{detail?.Gas}</Form.Text>
              </Form.Item>
              <Form.Item label="合约调用参数">
                <Form.Text>
                  <Table
                    bordered
                    recordKey="key"
                    records={paramsList}
                    columns={defaultColumn}
                    addons={[
                      groupable({
                        headerGroups: [
                          {
                            key: 'function',
                            title: detail?.ContractMethod ?? '-',
                            children: ['Id', 'key', 'value'],
                          },
                        ],
                      }),
                      autotip({
                        emptyText: '暂无数据',
                      }),
                    ]}
                  />
                </Form.Text>
              </Form.Item>
              <Form.Item label="合约执行结果">
                <Form.Text>
                  <TextArea readOnly value={contractResultDecode} size="full" rows={15}/>
                </Form.Text>
              </Form.Item>
              <Form.Item label="参与共识的组织">
                <Form.Text>{detail?.OrgList?.join(', ')}</Form.Text>
              </Form.Item>
            </Form>
          </Card.Body>
        </Card>
      </Card>
    </>
  );
}
