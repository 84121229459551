/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { useCallback, useState } from 'react';
import { fetchCertList, fetchDetailCert, fetchDownloadCert, fetchGenerateCert, fetchImportCert } from '.';
import {
  ICertItem,
  IDetailCertParam,
  IDownloadCertParam,
  IFetchCertListParam,
  IGenerateCertParam,
  IImportCertParam,
} from './interface';
import { useDispatchMessage } from 'src/utils/hooks';
import { saveBlobAs } from '../../../utils/common';
import { Error } from 'src/common/interface';

/**
 * 获取证书列表
 */
export function useFetchCertList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<ICertItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetch = useCallback(async (params: IFetchCertListParam) => {
    try {
      const result = await fetchCertList(params);
      setList(result.data.Response.GroupList);
      setTotalCount(result.data.Response.TotalCount);
    } catch (err) {
      errorNotification({
        title: '获取证书列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return { list, totalCount, fetch };
}

/**
 * 查看证书
 */
export function useFetchCertDetail() {
  const { errorNotification } = useDispatchMessage();
  const [certDetail, setCertDetail] = useState<string>('');

  const fetch = useCallback(async (params: IDetailCertParam) => {
    try {
      const result = await fetchDetailCert(params);
      setCertDetail(result.data.Response.Data.CertDetail);
    } catch (err) {
      errorNotification({
        title: '获取证书失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    certDetail,
    fetch,
  };
}

/**
 * 下载证书
 */
export function useFetchDownloadCert() {
  const { errorNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IDownloadCertParam) => {
    try {
      const result = await fetchDownloadCert(params);
      const { data: blob, headers } = result;
      saveBlobAs(headers, blob);
    } catch (err) {
      errorNotification({
        title: '下载证书失败',
        description: (err as Error).Message,
      });
    }
  }, []);
  return {
    fetch,
  };
}

/**
 * 申请证书
 */
export function useFetchGenerateCert() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const fetch = useCallback(async (params: IGenerateCertParam) => {
    try {
      await fetchGenerateCert(params);
      setIsSuccess(true);
      successNotification({
        description: '申请证书成功',
      });
    } catch (err) {
      errorNotification({
        title: '申请证书失败',
        description: (err as Error).Message,
      });
    }
  }, []);
  return {
    isSuccess,
    fetch,
  };
}

/**
 * 导入证书
 */
export function useFetchImportCert() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IImportCertParam) => {
    try {
      await fetchImportCert(params);
      successNotification({
        description: '导入证书成功',
      });
    } catch (err) {
      errorNotification({
        title: '导入证书失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    fetch,
  };
}
