/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { Ref, useCallback, useContext, useEffect, useImperativeHandle } from 'react';
import { Card, Checkbox, Form, H3, Justify, Layout, Select } from 'tea-component';
import { Control, Controller, useForm } from 'react-hook-form';
import formUtils from '../../utils/form-utils';
import { useFetchAuthOrgList, useFetchAuthRoleList, useFetchChainAuthList } from '../../common/apis/chains/hooks';
import { ChainDetailContext } from './chain-detail';
import { Chain, ChainAuth as ChainAuthType } from '../../common/apis/chains/interface';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { CheckChangeContext } from 'tea-component/lib/check/Check';

const { Content } = Layout;

export const AuthRoleMap = {
  0: 'Admin',
  1: 'Client',
};

/**
 * 权限配置类型
 */
export const ChainAuthTypes: {
  [key: number]: {
    value: string;
    title: string;
  };
} = {
  0: {
    title: '增加新节点',
    value: 'NODE_ADDR_UPDATE',
  },
  1: {
    title: '更新信任根CA证书',
    value: 'TRUST_ROOT_UPDATE',
  },
  2: {
    title: '踢出共识节点',
    value: 'CONSENSUS_EXT_DELETE',
  },
  3: {
    title: '更新区块配置',
    value: 'BLOCK_UPDATE',
  },
  4: {
    title: '部署合约',
    value: 'INIT_CONTRACT',
  },
  5: {
    title: '升级合约',
    value: 'UPGRADE_CONTRACT',
  },
  6: {
    title: '冻结合约',
    value: 'FREEZE_CONTRACT',
  },
  7: {
    title: '解冻合约',
    value: 'UNFREEZE_CONTRACT',
  },
  8: {
    title: '废止合约',
    value: 'REVOKE_CONTRACT',
  },
  9: {
    title: '修改链权限',
    value: 'PERMISSION_UPDATE',
  },
};
/**
 * 投票规则
 */
export const VOTE_RULE_MAP: {
  [key: number]: {
    title: string;
    value: string;
  };
} = {
  0: {
    title: 'Majority',
    value: 'MAJORITY',
  },
  1: {
    title: 'ANY',
    value: 'ANY',
  },
  3: {
    title: 'ALL',
    value: 'ALL',
  },
  4: {
    title: 'Forbidden',
    value: 'FORBIDDEN',
  },
};

export default function ChainAuth() {
  const { chainId } = useContext(ChainDetailContext);
  const { run: fetchChainAuthList, data: authTypes } = useFetchChainAuthList();
  // const chainAuthModal = useRef<any>();
  useEffect(() => {
    chainId && fetchChainAuthList(chainId);
  }, []);

  return (
    <Content>
      <Content.Header title="区块链管理/区块链概览/链管理权限配置" />
      <Content.Body full>
        {/* <Card>*/}
        {/*  <Card.Body>*/}
        {/*    <Justify className={'auth-top-tip'} left={<H3>*/}
        {/*      此处默认展示常用的一些链账户权限，和投票策略，如需管理更多权限，可点击修改更多权限按钮。如想知道轮奂链一共有哪些权限，可查看开源文档<ExternalLink*/}
        {/*      href={'https://docs.chainmaker.org.cn/tech/%E8%BA%AB%E4%BB%BD%E6%9D%83%E9%99%90%E7%AE%A1%E7%90%86.html#id9'}>身份权限管理模块</ExternalLink>。*/}
        {/*    </H3>} right={<Button type={'primary'} onClick={() => {*/}
        {/*      chainAuthModal.current.show();*/}
        {/*    }}>*/}
        {/*      修改更多权限*/}
        {/*    </Button>}/>*/}
        {/*  </Card.Body>*/}
        {/* </Card>*/}
        {authTypes.map((item) => (
          <ChainAuthCard
            key={item.Type}
            title={`${ChainAuthTypes[item.Type].title}(${ChainAuthTypes[item.Type].value})`}
            chainId={chainId as string}
            auth={item}
          />
        ))}
        {/* <ChainAuthModal ref={chainAuthModal} />*/}
      </Content.Body>
    </Content>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ChainAuthCardContainer(props: { title: string; chainId: string; auth: ChainAuthType }, parentRef: Ref<any>) {
  const {
    control,
    // getValues,
    setValue,
    formState: { isValidating, isSubmitted },
  } = useForm({ mode: 'onChange' });

  // const { run: modifyAuth } = useFetchChainAuthModify();
  // const handleSubmit = useCallback(() => {
  //   modifyAuth({
  //     Type: props.auth.Type,
  //     Rule: getValues('Rule'),
  //     ChainId: props.chainId,
  //     PercentNum: getValues('PercentNum'),
  //     OrgList: getValues('OrgList').map((item: string) => ({
  //       OrgId: item,
  //     })),
  //     RoleList: getValues('RoleList').map((item: string) => ({
  //       Role: +item,
  //     })),
  //   });
  // }, []);

  useImperativeHandle(parentRef, () => ({}));

  return (
    <Card>
      <Card.Body
        title={
          <Justify
            left={<H3>{props.title}</H3>}
            // right={<Button onClick={handleSubmit}>确认修改 </Button>}
          />
        }
      >
        <Form>
          <ChainAuthForm
            isDefaultAuth
            setValue={setValue}
            auth={props.auth}
            chainId={props.chainId}
            control={control}
            isSubmitted={isSubmitted}
            isValidating={isValidating}
          />
        </Form>
      </Card.Body>
    </Card>
  );
}

/**
 * 参与组织全选
 */
const select_all_org = {
  OrgName: '全选',
  OrgId: '__all_',
};

function ChainAuthForm({
  control,
  auth,
  chainId,
  isValidating,
  isSubmitted,
  isDefaultAuth,
  setValue,
}: {
  control: Control;
  setValue: UseFormSetValue<any>;
  auth: ChainAuthType | undefined;
  chainId: Chain['ChainId'];
  isValidating: boolean;
  isSubmitted: boolean;
  /**
   * 是否是默认权限，默认权限需要请求后台拿到缺省选中值
   */
  isDefaultAuth: boolean;
}) {
  const { orgs, run: fetchOrgList } = useFetchAuthOrgList();
  const { data: roles, run: fetchAuthRoleList } = useFetchAuthRoleList();
  const onOrgSelectChange = useCallback(
    (value: string[], context: CheckChangeContext) => {
      if (context.check.name === select_all_org.OrgId && context.check.value) {
        setValue('OrgList', [select_all_org.OrgId, ...orgs.map((item) => item.OrgId)]);
      } else if (context.check.name !== select_all_org.OrgId && !context.check.value) {
        setValue(
          'OrgList',
          value.filter((item) => item !== select_all_org.OrgId),
        );
      } else {
        setValue('OrgList', value);
      }
    },
    [orgs],
  );

  useEffect(() => {
    if (auth) {
      fetchOrgList(chainId, isDefaultAuth ? auth.Type : undefined);
      fetchAuthRoleList({ ChainId: chainId, Type: isDefaultAuth ? auth.Type : undefined });
    }
  }, [auth, isDefaultAuth]);

  return (
    <>
      {auth && Boolean(roles.length) && Boolean(orgs?.length) && (
        <>
          <Controller
            control={control}
            rules={{
              validate: () => undefined,
              required: true,
            }}
            name="Rule"
            defaultValue={String(auth!.PolicyType)}
            render={({ field, fieldState }) => (
              <Form.Item
                label="投票规则"
                required
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
              >
                <Select
                  disabled
                  placeholder={'请选择投票规则'}
                  options={Object.entries(VOTE_RULE_MAP).map((arr) => ({
                    text: arr[1].title,
                    value: String(arr[0]),
                  }))}
                  {...field}
                />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            rules={{
              required: true,
              validate: () => undefined,
            }}
            defaultValue={(orgs || []).filter((item) => item.Selected).map((item) => item.OrgId)}
            name="OrgList"
            render={({ field, fieldState }) => (
              <Form.Item
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
                required
                label="参与组织"
              >
                <Checkbox.Group {...field} onChange={onOrgSelectChange} disabled>
                  {[select_all_org, ...(orgs || [])].map((item) => (
                    <Checkbox key={item.OrgId} name={item.OrgId}>
                      {item.OrgName}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name="RoleList"
            rules={{
              required: true,
              validate: () => undefined,
            }}
            defaultValue={roles.filter((item) => item.Selected).map((item) => String(item.Role))}
            render={({ field, fieldState }) => (
              <Form.Item
                required
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
                label="参与角色"
              >
                <Checkbox.Group {...field} disabled>
                  {roles.map((item) => (
                    <Checkbox key={item.Role} name={String(item.Role)}>
                      {AuthRoleMap[item.Role]}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
          />
        </>
      )}
    </>
  );
}

const ChainAuthCard = React.forwardRef(ChainAuthCardContainer);

/**
 * 用户自定义权限
 */
/* const custom_auth = {
  Type: -1,
  PolicyType: -1,
};*/
/*
function ChainAuthModalContainer({}, ref: any) {
  const [visible, setVisible] = useState(false);
  const { chainId } = useContext(ChainDetailContext);
  const { run: fetchChainAuthList, data: authTypes } = useFetchChainAuthList();
  const {
    setValue,
    control,
    getValues,
    reset,
    trigger,
    formState: { isValidating, isSubmitted, isValid },
  } = useForm({ mode: 'onBlur' });
  const { run: modifyAuth } = useFetchChainAuthModify();

  const close = useCallback(() => {
    setVisible(false);
    reset();
  }, []);

  const authType = useWatch({ name: 'Type', control });
  const customInputRef = useRef<any>();

  const handleSave = useCallback(() => {
    modifyAuth({
      Rule: getValues('Rule'),
      Type: +getValues('Type'),
      ChainId: chainId as string,
      OrgList: getValues('OrgList').map((item: string) => ({
        OrgId: item,
      })),
      RoleList: getValues('RoleList').map((item: string) => ({
        Role: +item,
      })),
    });
  }, []);

  useImperativeHandle(ref, () => ({
    show: () => setVisible(true),
  }));

  useEffect(() => {
    chainId && fetchChainAuthList(chainId);
  }, []);

  return (
    <Modal caption="修改更多权限" visible={visible} onClose={close}>
      <Modal.Body>
        <Form>
          <Controller
            render={({ field, fieldState }) => (
              <Form.Item
                label={'权限名称'}
                required
                status={getStatus({ fieldState, isSubmitted, isValidating })}
                message={fieldState.error?.message}
              >
                <>
                  <Select
                    className={authType == custom_auth.Type ? 'block' : undefined}
                    options={authTypes.concat([custom_auth]).map((item) => ({
                      text: item.Type,
                      value: String(item.Type),
                    }))}
                    {...field}
                  />
                  {authType == custom_auth.Type && (
                    <Input
                      className={'tea-mt-2n'}
                      ref={customInputRef}
                      onBlur={() => {
                        trigger('Type');
                      }}
                    />
                  )}
                </>
              </Form.Item>
            )}
            name={'Type'}
            control={control}
            rules={{
              validate: (value) => {
                if (value == custom_auth.Type && !customInputRef.current.value) {
                  return '请输入自定义权限名';
                }
              },
            }}
          />
          <ChainAuthForm
            setValue={setValue}
            control={control}
            auth={authTypes.find((item) => item.Type == authType)}
            chainId={chainId as string}
            isValidating={isValidating}
            isDefaultAuth={false}
            isSubmitted={isSubmitted}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={!isValid} onClick={handleSave}>
          确定
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}
*/

// const ChainAuthModal = React.forwardRef(ChainAuthModalContainer);
