/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect } from 'react';
import { Button, Input, Modal } from 'tea-component';
import { useFetchCertDetail, useFetchDownloadCert } from 'src/common/apis/certificates/hooks';
import { ValueOf } from 'src/common/interface';
import { CERTTYPE, CERTUSE } from 'src/common/apis/certificates/interface';

const { TextArea } = Input;

interface ICertDetailModalParam {
  visible: boolean;
  CertType: ValueOf<typeof CERTTYPE>;
  CertUse: ValueOf<typeof CERTUSE> | undefined;
  CertId: number | undefined;
  close: () => void;
}

const CERT_TYPE_MAP = {
  0: '组织',
  1: '节点',
  2: '用户',
};

const CERT_USE_MAP = {
  0: '签名证书',
  1: '签名私钥',
  2: 'TLS证书',
  3: 'TLS私钥',
};

export function CertDetailModal({ visible, CertType, CertUse, CertId, close }: ICertDetailModalParam) {
  const caption = `${CertType !== undefined ? CERT_TYPE_MAP[CertType] : ''}${
    CertUse !== undefined ? CERT_USE_MAP[CertUse] : ''
  }`;
  const { certDetail, fetch } = useFetchCertDetail();
  const { fetch: fetchDownload } = useFetchDownloadCert();

  useEffect(() => {
    if (visible && CertId !== undefined && CertUse !== undefined) {
      fetch({ CertId, CertUse });
    }
  }, [visible, CertId, CertUse]);

  const download = async () => {
    if (CertId !== undefined && CertUse !== undefined) {
      await fetchDownload({ CertId, CertUse });
      close();
    }
  };
  return (
    <Modal caption={caption} visible={visible} onClose={close}>
      <Modal.Body>
        <TextArea readOnly size="full" rows={20} value={certDetail} />
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={!certDetail} onClick={download}>
          下载
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}
