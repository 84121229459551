/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Card, Form, PagingQuery, Table, TableColumn } from 'tea-component';
import { formatDate } from 'src/utils/date';
import { IChainBrowserTxItem } from 'src/common/apis/chains/interface';
import { PAGE_SIZE_OPTIONS } from 'src/utils/common';
import { useFetchBlockDetail, useFetchDealList } from 'src/common/apis/chains/hooks';

const { pageable, autotip } = Table.addons;

export function BlockDetail({ id, chainId }: { id: number; chainId: string | null }): JSX.Element {
  const { url } = useRouteMatch();
  const { detail, fetch: fetchDetail } = useFetchBlockDetail();
  const { list, totalCount, fetch: fetchList } = useFetchDealList();
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const defaultColumn: TableColumn<IChainBrowserTxItem>[] = [
    {
      key: 'BlockHeight',
      header: '区块高度',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { BlockHeight } = record;
        return <>{BlockHeight}</>;
      },
    },
    {
      key: 'TxId',
      header: '交易ID',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { TxId, Id } = record;
        return <Link to={`${url}?type=deal&Id=${Id}`}>{TxId}</Link>;
      },
    },
    {
      key: 'OrgName',
      header: '发起组织',
    },
    {
      key: 'UserName',
      header: '用户名',
    },
    {
      key: 'ContractName',
      header: '合约',
    },
    {
      key: 'Timestamp',
      header: '上链时间',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { Timestamp } = record;
        return <>{formatDate(Timestamp)}</>;
      },
    },
  ];

  useEffect(() => {
    if (!chainId || !id) return;
    fetchDetail({
      ChainId: chainId,
      Id: id,
    });
  }, [chainId, id, fetchDetail]);

  useEffect(() => {
    if (!chainId || !detail) return;
    fetchList({
      ChainId: chainId,
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
      BlockHash: detail?.BlockHash,
      BlockHeight: detail?.BlockHeight,
    });
  }, [chainId, pageQuery, detail, fetchList]);

  return (
    <>
      <Card>
        <Card bordered style={{ border: 'none' }}>
          <Card.Body title="区块基本信息">
            <Form>
              <Form.Item label="区块高度">
                <Form.Text>{detail?.BlockHeight}</Form.Text>
              </Form.Item>
              <Form.Item label="区块哈希">
                <Form.Text>{detail?.BlockHash}</Form.Text>
              </Form.Item>
              <Form.Item label="前区块哈希">
                <Form.Text>{detail?.PreBlockHash}</Form.Text>
              </Form.Item>
              <Form.Item label="时间戳">
                <Form.Text>{formatDate(detail?.Timestamp)}</Form.Text>
              </Form.Item>
            </Form>
          </Card.Body>
        </Card>

        <Card bordered style={{ border: 'none' }} className="tea-mt-0">
          <Card.Body title="其他信息">
            <Form>
              <Form.Item label="读写集哈希">
                <Form.Text>{detail?.RwSetHash}</Form.Text>
              </Form.Item>
              <Form.Item label="交易默克尔根">
                <Form.Text>{detail?.TxRootHash}</Form.Text>
              </Form.Item>
              <Form.Item label="DAG特性值">
                <Form.Text>{detail?.DagHash}</Form.Text>
              </Form.Item>
              <Form.Item label="出块组织">
                <Form.Text>{detail?.OrgName}</Form.Text>
              </Form.Item>
              <Form.Item label="出块节点">
                <Form.Text>{detail?.NodeName}</Form.Text>
              </Form.Item>
            </Form>
          </Card.Body>
        </Card>

        <Card bordered style={{ border: 'none' }} className="tea-mt-0">
          <Card.Body title="交易信息">
            <Table
              recordKey="Id"
              records={list}
              columns={defaultColumn}
              addons={[
                pageable({
                  recordCount: totalCount,
                  pageIndex: pageQuery.pageIndex,
                  pageSize: pageQuery.pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  onPagingChange: ({ pageIndex, pageSize }) =>
                    setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
                }),
                autotip({
                  emptyText: '暂无数据',
                }),
              ]}
            />
          </Card.Body>
        </Card>
      </Card>
    </>
  );
}
