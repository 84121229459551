/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useContext, useEffect, useState } from 'react';
import { Card, Justify, Layout, PagingQuery, SearchBox, Table, TableColumn, Text } from 'tea-component';
import { IChainOrgItem } from 'src/common/apis/chains/interface';
import { formatDate } from 'src/utils/date';
import { useFetchChainOrgList } from 'src/common/apis/chains/hooks';
import { ChainDetailContext } from '../chain-detail';
import { PAGE_SIZE_OPTIONS } from 'src/utils/common';

const { Content } = Layout;
const { pageable, autotip } = Table.addons;

export function ChainOrganization() {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueInput, setSearchValueInput] = useState<string>('');
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const { chainId } = useContext(ChainDetailContext);
  const { list, totalCount, fetch } = useFetchChainOrgList();

  useEffect(() => {
    if (!chainId) {
      return;
    }
    fetch({
      ChainId: chainId,
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
      OrgName: searchValue,
    });
  }, [fetch, searchValue, chainId, pageQuery]);

  const defaultColumn: TableColumn<IChainOrgItem>[] = [
    {
      key: 'OrgId',
      header: '组织ID',
    },
    {
      key: 'OrgName',
      header: '组织名称',
    },
    {
      key: 'CreateTime',
      header: '创建时间',
      render: ({ CreateTime }) => formatDate(CreateTime),
    },
    {
      key: 'NodeNum',
      header: '节点数',
      // eslint-disable-next-line react/display-name
      render: ({ NodeNum }: IChainOrgItem) => <Text>{NodeNum}</Text>,
    },
  ];
  return (
    <>
      <Layout>
        <Content>
          <Content.Header title="区块链管理/组织信息" />
          <Content.Body full>
            <Justify
              right={
                <SearchBox
                  placeholder="请输入组织名搜索"
                  size="l"
                  value={searchValueInput}
                  onChange={(value) => setSearchValueInput(value)}
                  onSearch={(value) => setSearchValue(value ?? '')}
                  onClear={() => setSearchValue('')}
                />
              }
            />
            <Card className="tea-mt-5n">
              <Table
                recordKey="Id"
                records={list}
                columns={defaultColumn}
                addons={[
                  pageable({
                    recordCount: totalCount,
                    pageIndex: pageQuery.pageIndex,
                    pageSize: pageQuery.pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    onPagingChange: ({ pageIndex, pageSize }) =>
                      setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
                  }),
                  autotip({
                    emptyText: '暂无数据',
                    isFound: !!searchValue.length,
                    onClear: () => {
                      setSearchValue('');
                      setSearchValueInput('');
                    },
                  }),
                ]}
              />
            </Card>
          </Content.Body>
        </Content>
      </Layout>
    </>
  );
}
